body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.hidden {
  display: none;
}

.PeriodSelector {
  font-size: 80%;
}

.PeriodSelector .noUi-marker-horizontal.noUi-marker-large {
  height: 10px;
}

.PeriodSelector .noUi-connect {
  background-color: #0d47a1;
}

.PeriodSelector .noUi-tooltip {
  padding: 2px;
}

