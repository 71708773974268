.PeriodSelector {
  font-size: 80%;
}

.PeriodSelector .noUi-marker-horizontal.noUi-marker-large {
  height: 10px;
}

.PeriodSelector .noUi-connect {
  background-color: #0d47a1;
}

.PeriodSelector .noUi-tooltip {
  padding: 2px;
}
